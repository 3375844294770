import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCookie } from "react-use-cookie";
import { useAccount } from "wagmi";


import IconBlueMark from "../../../../assets/svgs/blueMarkIconWhite.svg";
import Logo from "../../../../assets/svgs/logo.svg";
import LogoLetterColor from "../../../../assets/svgs/logoLetterColor.svg";
import LogoColor from "../../../../assets/svgs/logoColor.svg";
import LogoColorBeta from "../../../../assets/svgs/logoColorBeta.svg";
import IconOnChainProfile from "../../../../assets/svgs/onChainProfileInfobarIcon.svg";
import useUserData from "../../../../hooks/useUserData";
import {
  selectInfinityPassBadge,
  selectOnChainProfile,
  selectPriorityPassBadge,
} from "../../../../Redux/slices/auth.slice";
import LanguageChangeModal from "../../../languageChangeModal/languageModal";
import NavbarInfo from "./NavbarInfo";
import MobileNavbar from "./mobileNavbar";

const WalletConnectButton = dynamic(
  () => import("./navbar/walletConnectButton"),
  {
    ssr: false,
  },
);
export const REF_2_DISABLED = true;
const blueMarkinfobar = false;

const tabs = [
  { label: "Feed", path: "/feed" },
  { label: "Dashboard", path: "/dashboard" },
  { label: "Social Graph", path: "/social-graph" },
  { label: "Groups", path: "/groups/list?page=1" },
];

const Navbar = ({
  infoBarsEnabled,
  hasColorLogo,
  hasUserPanel = true
}: {
  infoBarsEnabled?: boolean;
  hasColorLogo?: boolean;
  hasUserPanel?: boolean
}) => {
  const { t } = useTranslation("common");
  const router = useRouter();

  const onChainProfile = useSelector(selectOnChainProfile);
  const [languageModal, setLanguageModal] = React.useState<boolean>(false);
  const [scrolled, setScrolled] = React.useState<boolean>(false);
  const [connected, setConnected] = React.useState<boolean>(false);
  const priorityPassBadge = useSelector(selectPriorityPassBadge);
  const infinityPassBadge = useSelector(selectInfinityPassBadge);
  const { isConnected, address } = useAccount();
  const { i18n } = useTranslation();
  const userData = useUserData();
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false);
  const landing = router.asPath === "/";

  React.useEffect(() => {
    if (i18n.language === "pk") {
      document.body.classList.add("pk");
    }
    setConnected(isConnected);
    window.addEventListener("scroll", changeNavbarOnScroll);
    return () => window.removeEventListener("scroll", changeNavbarOnScroll);
  }, [isConnected]);

  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth <= 1024;
      setIsMobile(isMobile);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const changeNavbarOnScroll = () => {
    if (window.scrollY > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const isStaging = !!(process.env.NEXT_PUBLIC_APP_CONFIG === "staging");
  return (
    <>
      <LanguageChangeModal
        onClose={() => setLanguageModal(false)}
        open={languageModal}
      />
      <header className={`${!(router.asPath === "") && "bg-gray-900"} bg-gray-900 ${scrolled ? "navbar scrolled" : landing ? "navbar landing" : "navbar"}`}>
        { isMobile &&
          <MobileNavbar isVisible={showMobileNav} close={() => setShowMobileNav(false)}/>
        }
        {infoBarsEnabled && (
          <div className="[&>div]:border-b">
            {blueMarkinfobar && (
              <NavbarInfo
                icon={IconBlueMark}
                content={t("blueMarkNavbarText")}
                button={{ label: t("navbarButtonLabel"), url: "/" }}
                commingSoon
              />
            )}
            {!onChainProfile &&
              !REF_2_DISABLED &&
              !getCookie(`on_chain_profile_active___${address}`) && (
                <NavbarInfo
                  icon={IconOnChainProfile}
                  content={t("onChainNavbarText")}
                  button={{
                    label: t("navbarButtonLabel"),
                    url: "/onchain-profile",
                  }}
                />
              )}
          </div>
        )}

        <nav>
          <div className="container">
            <div className="flex items-center">
              <Link href="/">
                {hasColorLogo ? (
                    <LogoLetterColor />
                ) : (
                  <Logo />
                )}
              </Link>

              <div className="ml-auto flex items-center">
                {hasColorLogo && (
                  <div className="column flex" style={{ gap: 16 }}>
                    {/* { router.asPath.startsWith('/groups') ?
                      <>
                        {tabs.map((tab, index) => (
                          <StyledButton key={index} onClick={() => router.push(tab.path)} isSelected={ router.asPath.startsWith('/groups') ? tab.path.startsWith('/groups') : router.asPath.startsWith(tab.path) } >
                            <span>{tab.label}</span>
                          </StyledButton>                            
                        ))}
                      </> :
                        <StyledButton onClick={() => router.push('/groups/list?page=1')} isSelected={ router.asPath.startsWith('/groups/') }>
                          <span>Groups</span>
                        </StyledButton>
                    } */}
                  </div>
                )}
                {hasUserPanel && <>
                <WalletConnectButton />
                { isMobile && isConnected && 
                  <button className="border p-2 rounded-lg bg-white ml-2" onClick={() => {setShowMobileNav(true)}}>
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="16"  height="16"  viewBox="0 0 24 24"  fill="none"  stroke="#6B7280"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-baseline-density-medium"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 20h16" /><path d="M4 12h16" /><path d="M4 4h16" /></svg>
                  </button>
                }</>}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
